import React from 'react';
import { graphql } from 'gatsby';
import { Layout, Slice, HomeSplashMetrics, HomeTwoColsCtaImageGrid } from 'components';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';

const IS_BROWSER = typeof window !== 'undefined';

const Page = ({ location, data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });
  const { page } = data;
  const { data: pageData } = page;
  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    schema,
  } = pageData;
  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
    schema: schema.text,
  };

  return (
    <Layout location={location} seo={seo}>
      {location.pathname === '/' && <HomeSplashMetrics />}
      {location.pathname === '/' && <HomeTwoColsCtaImageGrid />}
      {sliceData.map((slice) => (
        <Slice key={slice.id} data={slice} location={location} />
      ))}
    </Layout>
  );
};

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    page: prismicPage(uid: { eq: $uid }) {
      id
      uid
      prismicId
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        ogImage: open_graph_image {
          url
        }
        schema {
          text
        }
        body {
          ...CtaPageFragment
          ...ContactPageFragment
          ...FeatureColumnsPageFragment
          ...HeaderSlicePageFragment
          ...ImageGridPageFragment
          ...JobsBoardPageFragment
          ...MetricsCompactPageFragment
          ...OurOfficesPageFragment
          ...TestimonialsPageFragment
          ...TwoColsTextImagePageFragment
          ...UspGridPageFragment
          ...WysiwygPageFragment
        }
      }
    }
  }
`;

export default Page;
